import { nightsOfPeriod } from '../../core/helpers/nights-of-period';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        depositData: action.payload.items.map((deposit) => ({
          ...deposit,
          number_of_nights: nightsOfPeriod(
            new Date(deposit.arrival_date),
            new Date(deposit.departure_date),
          ),
        })),
        pagination: action.payload.pagination,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportFileId: action.payload.exportFileId,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
