import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { DateFormatterService } from '@app/core/services/date-formatter.service';

import { SearchParams } from '../models/objects/search-params';
import { removeAllNullishValues } from '../helpers';
import { ExportFileRequestSuccess } from '@app/models/types/export-file-request';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { DepositListExportFileRequest } from '@app/models/requests/deposit-list-request-export-file';

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(searchParams: SearchParams) {
    return this.http.get(
      `deposits?${generateSearchQuery(removeAllNullishValues(searchParams))}`,
    );
  }

  loadWidget(propertiesIds: number[], date_from: string | Date) {
    return this.http.get(
      `deposits_widget?registration_date=${this.dateFormatter.toServerFormat(
        date_from,
      )}&${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  exportFile(data: DepositListExportFileRequest) {
    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `deposits?${generateSearchQuery(removeAllNullishValues(data))}`,
    );
  }
}
