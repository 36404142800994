import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IDeposit } from '@app/models/objects';
import { Action } from '@ngrx/store';

import { SearchParams } from '../../models/objects/search-params';
import { DepositListExportFileRequest } from '@app/models/requests/deposit-list-request-export-file';

export enum ActionTypes {
  LOAD_REQUEST = '[Deposit] Load Request',
  LOAD_FAILURE = '[Deposit] Load Failure',
  LOAD_SUCCESS = '[Deposit] Load Success',

  LOAD_WIDGET_REQUEST = '[Deposit] Load Widget Request',
  LOAD_WIDGET_FAILURE = '[Deposit] Load Widget Failure',
  LOAD_WIDGET_SUCCESS = '[Deposit] Load Widget Success',

  EXPORT_FILE_REQUEST = '[Deposit] Export File Request',
  EXPORT_FILE_SUCCESS = '[Deposit] Export File Success',
  EXPORT_FILE_FAILURE = '[Deposit] Export File Failure',

  RESET_STATE = '[Deposit] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: SearchParams) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: {
      items: IDeposit[];
      pagination: IPagination;
    },
  ) {}
}

export class ExportFileRequest implements Action {
  readonly type = ActionTypes.EXPORT_FILE_REQUEST;
  constructor(public payload: { params: DepositListExportFileRequest }) {}
}

export class ExportFileSuccess implements Action {
  readonly type = ActionTypes.EXPORT_FILE_SUCCESS;
  constructor(public payload: { exportFileId: number }) {}
}
export class ExportFileFailure implements Action {
  readonly type = ActionTypes.EXPORT_FILE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ExportFileRequest
  | ExportFileSuccess
  | ExportFileFailure
  | ResetSuccessAction;
